<template>
  <s-input-number
    :id="`j-checkout-bag__${item.id}-${source}`"
    :key="`j-checkout-bag__${item.id}-${source}`"
    v-model="quantity"
    class="shopping-bag__item-input not-fsp-element"
    :max="isSheinClubGift ? quantity :99"
    :min="minNum"
    :disabled-inc="disabledAdd || editCartLoading"
    :disabled-dec="editCartLoading"
    :disabled="disabledInputNumber"
    @focus="focusNumber"
    @keyup="keyupNumber"
    @change="changeNumber"
    @increase="increase"
    @decrease="decrease"
  />
</template>

<script>
import { SInputNumber } from '@shein-aidc/sui-input-number'

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import * as api from 'public/src/pages/checkout/config/api.js'
import {
  handleOrderOrSingleIsMeetOrOver,
} from 'public/src/pages/cart_new/utils/index.js'
import { batchWishGoods } from 'public/src/pages/cart_new/utils/fetcher.js'

daEventCenter.addSubscriber({ modulecode: '1-11-2' })

export default {
  name: 'InputNumber',
  components: {
    SInputNumber,
  },
  props: {
    noRetentionPopup: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    isLastGoods: {
      type: Boolean,
      default: false
    },
    isPromitionGoods: {
      type: Boolean,
      default: false
    },
    isOutStockGood: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: ''
    },
    isSheinClubGift: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      quantity: 0,
      oldQuantity: 0,
      reachMax: false,
      delQuickshipInfo: { // 是否触发qs拆分其中一个删除
        isDel: false,
        id: '',
        item: {}
      },
    }
  },
  computed: {
    ...mapGetters([
      'flashSaleGroupList',
      'underPriceGroupList',
      'discountPriceGroupList',
    ]),
    ...mapState([
      'language',
      'checkout',
      'editCartInfo',
      'editCartLoading',
      'integrityPromotionInfo',
      'buyNowNo',
    ]),
    disabledAdd() {
      return this.isOutStockGood || this.isOrderOrSingleMeetOrOver || this.item.quantity >= 99 || this.isLimitProduct || (+this.item.quantity >= +this.item.realTimeInventory) || this.isSheinClubGift
    },
    disabledInputNumber() {
      // 只要是会员赠品且不是无库存，可编辑-号按钮
      if(!this.isOutStockGood && this.isSheinClubGift) {
        return false
      }
      return this.isPromitionGoods || this.isOutStockGood
    },
    minNum() {
      return this.isLastGoods ? (this.isSheinClubGift ? 0 : 1) : 0
    },
    promotionGroupInfo(){
      let groupPromotion = this.item.product.product_promotion_info?.find(promotion =>  [10, 11, 24, 30].includes(promotion.type_id))
      if(groupPromotion) {
        let groupName = [10, 11].includes(groupPromotion.type_id) ? 'flashSaleGroupList' : ([30].includes(groupPromotion.type_id) ? 'underPriceGroupList' : 'discountPriceGroupList')
        let groupPro = this.integrityPromotionInfo[groupPromotion.promotion_id] || {}
        return { ...groupPro, list: this[groupName][groupPromotion.promotion_id] || [] }
      }

      return {}
    },
    // 单品或订单限购满足数量或超过 置灰添加按钮
    isOrderOrSingleMeetOrOver(){
      let result = handleOrderOrSingleIsMeetOrOver(this.item, this.promotionGroupInfo, false)
      return Boolean(result)
    },
    // 限时折扣
    isLimitProduct(){
      let promotion = this.item.product.product_promotion_info?.find(item => item.type_id == 3)
      if(!promotion) return false
      const { promotion_id = '' } = promotion
      const promotionInfo = this.integrityPromotionInfo[promotion_id] || {}
      let { single } = promotionInfo.cart_limit || {}
      return single && this.item.quantity >= single
    },
    isSameItem() {
      let isSame = false
      const isSameItem = this.editCartInfo?.item?.id == this.item?.id

      // 外层购物袋触发，qs库存不足拆商品特殊场景
      if(this.editCartInfo?.quickshipInfo?.type != -1) {
        const isQuickship = this.editCartInfo?.quickshipInfo?.type == 1
        const realSame = (isQuickship && this.item.over_quick_ship != 1) || (!isQuickship && this.item.over_quick_ship == 1)
        isSame = isSameItem && realSame
      } else {
        isSame = isSameItem
      }

      return isSame
    },
  },
  watch: {
    item: {
      handler() {
        if(!this.editCartInfo.cartLoading) this.quantity = this.item.quantity
      },
      immediate: true,
      deep: true,
    },
    'editCartInfo.isCancel': {
      handler(v) {
        if(v && this.isSameItem && this.source == this.editCartInfo.source) {
          this.quantity = this.editCartInfo?.oldValue
          this.changeParamsStatus({ 
            type: 'editCartInfo', 
            params: { 
              isCancel: false,
              source: ''
            } 
          })
        }
      }
    },
    'editCartInfo.isQsConfirm': {
      handler(v) {
        if(v && this.isSameItem && this.source == this.editCartInfo.source) {
          this.changeParamsStatus({ 
            type: 'editCartInfo', 
            params: { 
              isQsConfirm: false,
              quickshipInfo: {
                type: -1,
                qsQuantity: 0,
                noQsQuantity: 0
              },
              source: ''
            } 
          })
          this.updateCartQuantity(this.editCartInfo?.currentValue, this.editCartInfo?.oldValue)
        }
      }
    },
    'editCartInfo.isDelete': {
      handler(v) {
        if(v && this.isSameItem && this.source == this.editCartInfo.source) {
          this.changeParamsStatus({ 
            type: 'editCartInfo', 
            params: { 
              isDelete: false,
              source: ''
            } 
          })
          this.deleteCart(this.editCartInfo?.item)
        }
      }
    },
    'editCartInfo.isMoveWhislist': {
      handler(v) {
        if(v && this.isSameItem && this.source == this.editCartInfo.source) {
          this.changeParamsStatus({ 
            type: 'editCartInfo', 
            params: { 
              isMoveWhislist: false,
              source: ''
            } 
          })
          this.moveCartToWhislist(this.editCartInfo?.item)
        }
      }
    },
  },
  mounted() {
    document.querySelector(`#j-checkout-bag__${this.item.id}-${this.source} .sui-input-number__prepend`)?.addEventListener('click', this.handleDisableToast)
  },
  beforeUnmount() {
    document.querySelector(`#j-checkout-bag__${this.item.id}-${this.source} .sui-input-number__prepend`)?.removeEventListener('click', this.handleDisableToast)
  },
  methods: {
    ...mapMutations(['assignState', 'changeParamsStatus']),
    ...mapActions(['updateCart']),
    template,
    handleDisableToast() {
      // 存在从2－1场景也会触发提示，增加updateCartLoading判断
      if(this.isLastGoods && this.quantity == 1 && !this.editCartLoading) {
        this.$message({
          message: this.language.SHEIN_KEY_PC_28768,
          type: 'error',
          offset: 186,
        })
      }
    },
    handleSuccessResponse({ isDel = false, id = '', item = {} } = {}) {
      this.updateCart({
        noUpdataShipping: 1,
        fn: () => {
          // 删除商品增加动效
          if(isDel && !!id) {
            this.changeParamsStatus({ 
              type: 'editCartInfo', 
              params: { 
                delItemInfo: {
                  id,
                  index: this.index,
                  quick_ship: !(item.quick_ship != 1 || item?.over_quick_ship == 1)
                }
              } 
            })
          }
        }
      })
      window.checkoutEventCenter.emit('update-shipping-method', { address: this.checkout.default_address, modifyCart: true })
    },
    async handleQtyResponse({ res, old_quantity = 0 }) {
      if ( res.code == 0 && res.info ) {
        this.handleSuccessResponse({ isDel: this.delQuickshipInfo.isDel, id: this.delQuickshipInfo.id, item: this.delQuickshipInfo.item  })
        this.delQuickshipInfo = {
          isDel: false,
          id: '',
          item: {}
        }
      } else {
        this.delQuickshipInfo = {
          isDel: false,
          id: '',
          item: {}
        }
        this.changeParamsStatus({ 
          type: 'editCartInfo', 
          params: { 
            cartLoading: false,
          } 
        })
        // 接口错误，恢复原来的数量
        this.quantity = old_quantity

        if (res.code == 300402) {
          this.$message({
            message: this.language.SHEIN_KEY_PC_15071,
            type: 'error',
            offset: 186,
          })

          //限时买折超过限购数
        } else if (['200401', '500301'].includes(res.code)) {
          this.$message({
            message: this.language.SHEIN_KEY_PC_14661,
            type: 'error',
            offset: 186,
          })
          //闪购限购
        } else if (['300714', '500302', '500306', '302444', '300417'].includes(res.code)) {
          this.$message({
            message: this.template(res.info.limitCount, res.tips),
            type: 'error',
            offset: 186,
          })
          // 订单限购
        } else if (res.code == 500303) {
          this.$message({
            message: this.template(res.info.limitCount, res.tips),
            type: 'error',
            offset: 186,
          })
        } else if (res.code == 300405) {
          this.$message({
            message: this.template(res.info.limitCount, this.language.SHEIN_KEY_PC_14560),
            type: 'error',
            offset: 186,
          })
        } else if ( res.code == 300470 ) {
          this.$message({
            message: this.template(res.info?.single_row_capacity_limit, res.tips),
            type: 'error',
            offset: 186,
          })
        } else {
          this.$message({
            message: res.tips || this.language.SHEIN_KEY_PC_15805,
            type: 'error',
            offset: 186,
          })
        }
      }
    },
    updateCartQuantity(currentValue, oldValue) {
      this.assignState({
        editCartLoading: true
      })
      this.changeParamsStatus({ 
        type: 'editCartInfo', 
        params: { 
          cartLoading: true,
        } 
      })
      api.updateCartItem({ quantity: currentValue, id: this.item.id, checkout_no: this.buyNowNo })
        .then(res => {
          this.assignState({
            editCartLoading: false
          })
          this.handleQtyResponse({ res, old_quantity: oldValue })
        })
        .catch(err => {
          this.delQuickshipInfo = {
            isDel: false,
            id: '',
            item: {}
          }

          this.assignState({
            editCartLoading: false
          })
          this.changeParamsStatus({ 
            type: 'editCartInfo', 
            params: { 
              cartLoading: false,
            } 
          })
          if (err.code === 'ERR_CANCELED') return
          // 接口错误，恢复原来的数量
          this.quantity = oldValue
        })
    },
    async deleteCart(item) {
      this.assignState({
        editCartLoading: true
      })
      this.changeParamsStatus({ 
        type: 'editCartInfo', 
        params: { 
          cartLoading: true,
        } 
      })
      // 立即购场景
      let buyNowNoAgument = {}
      if(this.buyNowNo){
        buyNowNoAgument = {
          buyNowNo: this.buyNowNo, 
          buyNowNoParams: {
            cart_id_list: [this.item.id],
            checkout_no: this.buyNowNo
          }
        }
      }
      api.deleteCartItem({ id: item.id, ...buyNowNoAgument })
        .then(res => {
          this.assignState({
            editCartLoading: false
          })
          if (res.code != 0) {
            this.changeParamsStatus({ 
              type: 'editCartInfo', 
              params: { 
                cartLoading: false,
              } 
            })
            // 接口错误，恢复原来的数量
            this.quantity = this.editCartInfo?.oldValue
            this.$message({
              message: this.language.SHEIN_KEY_PC_28781,
              type: 'error',
              offset: 186,
            })
            return
          }
          this.handleSuccessResponse({ isDel: true, id: item.id, item })
        })
        .catch(() => {
          this.assignState({
            editCartLoading: false
          })
          this.changeParamsStatus({ 
            type: 'editCartInfo', 
            params: { 
              cartLoading: false,
            } 
          })
          // 接口错误，恢复原来的数量
          this.quantity = this.editCartInfo?.oldValue
          this.$message({
            message: this.language.SHEIN_KEY_PC_28781,
            type: 'error',
            offset: 186,
          })
        })
    },
    async moveCartToWhislist(item) {
      this.assignState({
        editCartLoading: true
      })
      this.changeParamsStatus({ 
        type: 'editCartInfo', 
        params: { 
          cartLoading: true,
        } 
      })
      const ids = [item?.id]
      const res = await batchWishGoods({ ids, filterCheck: 1 })
      this.assignState({
        editCartLoading: false
      })

      if(res?.code == 0) {
        this.$message({
          message: this.language.SHEIN_KEY_PC_28676,
          type: 'info',
          offset: 186,
        })

        this.handleSuccessResponse({ isDel: true, id: item.id, item })
      } else {
        this.changeParamsStatus({ 
          type: 'editCartInfo', 
          params: { 
            cartLoading: false,
          } 
        })
        // 接口错误，恢复原来的数量
        this.quantity = this.editCartInfo?.oldValue
        this.$message({
          message: this.language.SHEIN_KEY_PC_28780,
          type: 'error',
          offset: 186,
        })
      }
    },
    sendBiMethod(type, params) {
      const source = {
        'focus': '1-11-2-8',
        'input': '1-11-2-9',
        'increase': '1-11-2-10',
        'decrease': '1-11-2-11'
      }
      const id = source[type]
      daEventCenter.triggerNotice({
        daId: id,
        extraData: {
          ...params
        }
      })
    },
    focusNumber() {
      this.sendBiMethod('focus')
      this.oldQuantity = this.quantity
    },
    // pc组件库首次会先触发change事件，再触发reach事件
    keyupNumber(e) {
      const keyNumber = parseInt(e.target.value)
      // 单个商品无法超过99件
      if(keyNumber > 99) {
        this.reachMax = true

        setTimeout(() => {
          this.quantity = this.oldQuantity
        }, 0)
      }
    },
    increase() {
      this.sendBiMethod('increase', {
        type: this.noRetentionPopup ? 2 : 1
      })
    },
    decrease() {
      this.sendBiMethod('decrease', {
        type: this.noRetentionPopup ? 2 : 1
      })
    },
    changeNumber(currentValue, oldValue, type) {
      const source = this.source == 'outer' ? 'outer' : 'inner'
      if(type == 'input') {
        this.sendBiMethod('input')
      }
      let finalVal = currentValue
      setTimeout(() => {
        this.quantity = parseInt(currentValue)
      }, 0)
      finalVal = parseInt(currentValue)

      // 当输入数量=0，自动变更为1（变为0只能通过减号触发）
      if(type == 'input' && finalVal <= 0) {
        setTimeout(() => {
          this.quantity = 1
        }, 0)
        finalVal = 1
      }
      // 单个商品无法超过99件
      if(type == 'input' && this.reachMax) {
        this.$message({
          message: this.template(99, this.language.SHEIN_KEY_PC_28914),
          type: 'error',
          offset: 186,
        })
        setTimeout(() => {
          this.quantity = oldValue
        }, 0)
        finalVal = oldValue
        this.reachMax = false
        return
      }
      
      // qs库存拆分场景减为0需要设置透明度删除dom
      if(type == 'lose' && finalVal == 0) {
        this.delQuickshipInfo = {
          isDel: true,
          id: this.item.id,
          item: this.item
        }
      }
      
      const { overQsQuantity, quickshipType, qsQuantity, noQsQuantity, nextVal } = this.handleQuickshipData({ finalVal, oldValue })
      finalVal = nextVal
      // 删除商品触发挽留弹窗（二次校验）（注意qs库存拆分场景如果同个id未全部删除不进行弹窗提醒）
      if(type == 'lose' && finalVal == 0) {
        if(this.noRetentionPopup) {
          this.$emit('del-second-confirm-handle')
        } else {
          this.assignState({
            showShoppingBagRetain: true,
            shoppingBagRetainIsLoaded: true
          })
        }
        this.changeParamsStatus({ 
          type: 'editCartInfo', 
          params: { 
            item: this.item,
            type: 'retain',
            oldValue,
            source
          } 
        })

        return
      }

      const goods_list = typeof sessionStorage.getItem('edit_quickship_goods') === 'string' ? JSON.parse(sessionStorage.getItem('edit_quickship_goods')) : []
      if(overQsQuantity && !goods_list.includes(this.item.id)) {
        this.assignState({
          showRecheckQuickship: true,
          recheckQuickshipIsLoaded: true
        })
        this.setQuickShipShowList(this.item.id)
        this.changeParamsStatus({ 
          type: 'editCartInfo', 
          params: { 
            item: this.item,
            type: 'quickship',
            quickshipInfo: {
              type: quickshipType,
              qsQuantity,
              noQsQuantity
            },
            currentValue: finalVal,
            oldValue,
            source
          } 
        })

        return
      }
      
      if(finalVal != oldValue) this.updateCartQuantity(finalVal, oldValue)
    },
    handleQuickshipData({ finalVal, oldValue }) {
      let overQsQuantity = false
      const isQuickshipCart = this.item?.quick_ship == 1
      let quickshipType = -1
      let qsQuantity = 0
      let noQsQuantity = 0
      let nextVal = finalVal

      if(isQuickshipCart && this.source == 'outer') {
        quickshipType = (!isQuickshipCart || this.source != 'outer') ? -1 : (this.item.over_quick_ship == 1 ? 0 : 1)
        const currItem = this.checkout.results?.carts?.carts?.find(item => item.id == this.item.id) || {}
        const prevQuantity = +currItem.quantity || 0 // 接口调用前的数量
        // -1的场景不需要特殊处理
        qsQuantity = quickshipType == 1 ? oldValue : prevQuantity - oldValue
        noQsQuantity = quickshipType == 0 ? oldValue : prevQuantity - oldValue
        nextVal = quickshipType == 1 ? (finalVal + noQsQuantity) : (finalVal + qsQuantity)

        const qs_stock = +this.item?.product?.qs_stock || 0
        overQsQuantity = nextVal > qs_stock
      }

      return {
        overQsQuantity,
        quickshipType,
        qsQuantity,
        noQsQuantity,
        nextVal
      }
    },
    setQuickShipShowList(id = '') {
      const goods_list = typeof sessionStorage.getItem('edit_quickship_goods') === 'string' ? JSON.parse(sessionStorage.getItem('edit_quickship_goods')) : []
      if(!!id && !goods_list.includes(id)) {
        goods_list.push(id)
        sessionStorage.setItem('edit_quickship_goods', JSON.stringify(goods_list))
      }
    },
  },
  emits: ['del-second-confirm-handle']
}
</script>

<style lang="less">
.shopping-bag__item-input {
  margin-top: 4px;
  &.sui-input-number {
    line-height: 1;
  }
}
</style>
