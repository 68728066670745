import { template } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { countryIdCodeEnum } from './enum'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
import BehaviorLabel from 'public/src/pages/cart_new/utils/behaviorLabel.js'
import * as api from 'public/src/pages/checkout/config/api.js'

export function couponCodeTips(obj) {
  const { status, language, paymentCouponListTxt, msg = '' } = obj
  let tips = msg || language.SHEIN_KEY_PC_14701
  switch (+status.error.couponCode) {
    case 300511: 
      tips = ''
      break
    case 300601:
      tips = language.SHEIN_KEY_PC_17900
      break
    case 300605:
      tips = language.SHEIN_KEY_PC_17916
      break
    case 300606:
      tips = language.SHEIN_KEY_PC_17917
      break
    case 300608: //优惠券不满足最低金额要求
      tips = template(status.error.couponCodeNum, tips)
      break
    case 300612: // 优惠券过期
      tips = language.SHEIN_KEY_PC_14703
      break
    case 300613: // 商品折后金额比使用优惠券后低
      tips = language.SHEIN_KEY_PC_14438
      break
    case 300614: // 优惠券已使用
      tips = language.SHEIN_KEY_PC_14439
      break
    case 300615:
      tips = language.SHEIN_KEY_PC_17917
      break
    case 300616:
      tips = language.SHEIN_KEY_PC_17918
      break
    case 300617: //优惠券和规定使用商品不对应
    case 300618:
    case 300619:
      tips = language.SHEIN_KEY_PC_14440
      break
    case 300620: //所有分档已使用完
      tips = language.SHEIN_KEY_PC_14762
      break
    case 300621: //不满足中间档次金额要求
      tips = language.SHEIN_KEY_PC_14763.replace(
        '[min_order]',
        status.error.couponCodeNum
      )
      break
    case 300623:
      tips = language.SHEIN_KEY_PC_17917
      break
    case 300624: //优惠券被占用
      tips = language.SHEIN_KEY_PC_16444
      break
    case 300625: // 买券失效
      tips = language.SHEIN_KEY_PC_17392
      break
    case 300626: // 特价及活动价商品无法用券
      tips = language.SHEIN_KEY_PC_17118
      break
    case 301108: // 已免邮无需用券
      tips = language.SHEIN_KEY_PC_17093
      break
    case 301109: // 券无法抵扣当前运输方式
      tips = language.SHEIN_KEY_PC_17094
      break
    case 300627: // 当前的支付方式不匹配当前的优惠券
      tips = paymentCouponListTxt
      break
    case 300628: // 当前券无法用于当前运输方式
      tips = language.SHEIN_KEY_PC_17717
      break
    case 300629:
      tips = language.SHEIN_KEY_PC_17900
      break
    case 300630:
      tips = language.SHEIN_KEY_PC_17901
      break
    case 300631:
      tips = template(status.error.couponCodeNum, language.SHEIN_KEY_PC_17902)
      break
    case 501050:
    case 501051:
      tips = language.SHEIN_KEY_PC_17919
      break
    case 300632:
      tips = language.SHEIN_KEY_PC_17949
      break
    case 300633:
      tips = language.SHEIN_KEY_PC_17950
      break
    case 300634:
      tips = template(
        status.error.couponMinOrder?.amountWithSymbol,
        status.error.couponMaxOrder?.amountWithSymbol,
        status.error.tips
      )
      break
    case 302446:
      tips = template(language.SHEIN_KEY_PC_19503, language['302446'])
      break
    case 302447:
      tips = template(status.error.couponCodeNum, language['302447'])
      break
    case 302448:
      tips = language['302448']
      break
    case 300438:
    case 300439:
      // 自动续费
      tips = language.SHEIN_KEY_PC_31095
      break
  }
  return tips
}

export function isToLowerCase(a, b) {
  return a?.toLowerCase() == b?.toLowerCase()
}

export function mallMerge(a, b) {
  const newMall = []
  if (a.length && b.length) {
    a.forEach((list) => {
      b.forEach((item) => {
        if (list.mall_code == item.mall_code) {
          newMall.push({ ...list, ...item })
        }
      })
    })
  }
  return newMall
}

export function newSetShippingList(mall_params) {
  let transport_type = []
  mall_params.forEach((item) => {
    transport_type.push(item.transport_type)
  })
  return [...new Set(transport_type)].join()
}

export function flatSkuInfo(list) {
  let res = []
  list.forEach((item) => {
    let obj = {}
    item?.sku_available_infos?.forEach((child) => {
      obj = Object.assign(
        {},
        {
          mall_code: item.mall_code,
          store_code: item.store_code,
          business_model: item.business_model,
          company_id: item.company_id,
          sku_info: child,
        }
      )
      res.push(obj)
    })
  })
  return res
}

/**
 * 处理积分、钱包、礼品卡等弹窗的限制商品数据
 * @param {Array} mallStoreInfos 价格计算的信息，取值积分和钱包、礼品卡是不一样的
 * @param {Array} carts 购物车信息
 * @param {string} type 类型，目前只在积分（point）里面用到
 * @param {string} countryId 国家或者地区码，需要对巴西按照商品模式进行处理
 * @returns {}
*/
export function handleLimitGoods({ mallStoreInfos, carts, type = '', countryId }) {
  const walletLimit = []
  const giftCardLimitGoods = []
  const pointLimitGoods = []
  // 0、3、4都是自营，1是商家，目前只有两种
  const businessModelTypeMap = {
    SELF_SUPPORT: [0, 3, 4],
    MERCHANT: [1]
  }
  
  if (!mallStoreInfos.length || !carts.length){
    return { walletLimit, giftCardLimitGoods, pointLimitGoods }
  }

  // 处理积分相关
  if (type === 'point') {
    carts.forEach((item) => {
      const filterLimitList = mallStoreInfos.filter(limit => String(limit?.cart_id) === String(item.id)) || []
      
      if (filterLimitList.length) {
        let isDiscountRateEqual = false
        const discountRate = filterLimitList[0]?.point_max_discount_rate || '0%' // 折扣比例为空的情况，就是0，没有折扣
     
        // 巴西需要按照折扣比例系数、商业模式两者进行聚合
        if (countryId && Number(countryId) === countryIdCodeEnum.BRAZIL) {
          if (pointLimitGoods.length) {
            pointLimitGoods.forEach(limtData => {
              const businessModelArr = limtData.businessModel === 'self-support' ? businessModelTypeMap.SELF_SUPPORT : businessModelTypeMap.MERCHANT
              if (limtData.discountRate === discountRate && businessModelArr.includes(Number(item?.skc_business_model))) {
                isDiscountRateEqual = true
                limtData.goodsInfo.push(item)
              }
            })
          }
        } else {
          if (pointLimitGoods.length) {
            pointLimitGoods.forEach(limtData => {
              if (limtData.discountRate === discountRate) {
                isDiscountRateEqual = true
                limtData.goodsInfo.push(item)
              }
            })
          }
        }
        if (!isDiscountRateEqual) {
          let skcBusinessModel = ''
          
          if (businessModelTypeMap.SELF_SUPPORT.includes(Number(item?.skc_business_model))) {
            skcBusinessModel = 'self-support' // 自营
          } else if (businessModelTypeMap.MERCHANT.includes(Number(item?.skc_business_model))) {
            skcBusinessModel = 'merchant' // 商家
          }
          pointLimitGoods.push({ type: 'point', goodsInfo: [item], discountRate, businessModel: skcBusinessModel })
        }
      }
    })

    // 调整顺序，将折扣最大的放在数组最前面，依次排序
    if (pointLimitGoods.length > 1){
      pointLimitGoods.sort((obj1, obj2) => obj2.discountRate.replace(/%/g, '') - obj1.discountRate.replace(/%/g, ''))
    }
  } else {
    const skuList = flatSkuInfo(mallStoreInfos)
    carts.forEach((item) => {
      const filterLimitList = skuList.filter(limit => limit.mall_code == item.mall_code && item.product.sku_code == limit.sku_info.sku_code ) || []
  
      // wallet_available 钱包是否可用, 0、不可用 1、可用 不用礼品卡/钱包时默认null
      // gift_card_available 礼品卡是否可用, 0、不可用 1、可用 不用礼品卡/钱包时默认null
      if ( filterLimitList.length && !filterLimitList[0]?.sku_info.wallet_available) {
        walletLimit.push(item)
      }
      if ( filterLimitList.length && !filterLimitList[0]?.sku_info.gift_card_available) {
        giftCardLimitGoods.push(item)
      }
    })
  }

  return { walletLimit, giftCardLimitGoods, pointLimitGoods }
}

// 无地址时调用
export function handleCartShipFreeMall(cartInfo){
  let mallList = cartInfo?.mall_list || []
  let shippingInfoParams = []
  mallList.forEach(item => {
    let cart_list = cartInfo?.carts?.filter(cartItem => cartItem.mall_code == item.mall_code)
    let mallInfo = {
      mall_code: item.mall_code,
      is_free_activity_limit: item.is_free_activity_limit,
      total_price: item.total_price?.amount,
      skc_list: cart_list.map(cartItem => cartItem.product.goods_sn)
    }
    shippingInfoParams.push(mallInfo)
  })
  return shippingInfoParams
}

export function handleShipFreeMall(cartFreeInfoList){
  let shippingInfoParams = []
  if(!cartFreeInfoList?.length) return shippingInfoParams

  cartFreeInfoList.forEach(item => {
    let mallInfo = {
      mall_code: item.mall_code,
      is_free_activity_limit: item.is_free_activity_limit,
      total_price: item.total_price?.amount,
      skc_list: item.skc_list,
      cat_id_list: item.cat_id_list,
      free_shipping_product_price: item.free_shipping_product_price?.amount
    }
    shippingInfoParams.push(mallInfo)
  })

  return shippingInfoParams
}

function handleShippingAnalysisParam(shippingInfoAvailable = {}, totalPriceAmount = 0) {
  const { transport_type, id, next_shipping_price, charging_type, shipping_detail_obj, max_shipping_price, originPrice, is_free_shipping, diff } = shippingInfoAvailable
  const analysisParam = {}
  const amount = totalPriceAmount

  //免邮运费
  if (originPrice && originPrice.amount) {
    analysisParam.origin_price = originPrice && originPrice.amount
    analysisParam.is_fullshippingactivity = is_free_shipping == 1 ? 1 : 0
  }

  //阶梯运费
  if (shipping_detail_obj) {
    let nowPrice = 0
    for (let i in shipping_detail_obj) {
      let info = shipping_detail_obj[i]
      if (+amount > +info.num && info.num_price) {
        nowPrice = info.num_price.amount
      }
    }
    analysisParam.step_fee = max_shipping_price.amount
    analysisParam.charging_type = charging_type
    analysisParam.num_price = nowPrice
    analysisParam.is_fullshippingactivity = diff <= 0 && !next_shipping_price ? 1 : 0
  }
  analysisParam.shipping_method = transport_type || id || ''

  return analysisParam
}

export function handleMallShippingAnalysisParam ({ cartInfo, mallShippingInfo }) {
  let mallShippingAnalysisParam = {
    platform: null, // 平台级免邮
  }
  for(let key in mallShippingInfo){
    let amount = 0
    let shipping_free_type = 1
    if(key == 'platform') {
      amount = cartInfo.totalPrice?.amount
      shipping_free_type = 0
    } else {
      amount = cartInfo.mall_list.find(item => item.mall_code == key)?.total_price?.amount
      shipping_free_type = 3
    }
    if(!mallShippingInfo[key].isFirstFreeShipping && mallShippingInfo[key].shippingInfoAvailable) {
      mallShippingAnalysisParam[key] = handleShippingAnalysisParam(mallShippingInfo[key].shippingInfoAvailable, amount)
      mallShippingAnalysisParam[key].shipping_free_type = shipping_free_type
    } else {
      mallShippingAnalysisParam[key] = null
    }
  }

  return mallShippingAnalysisParam
}

export function handleMallShippingAnalysis(mallShippingAnalysis = {}) {
  let result = {}
  for(let type in mallShippingAnalysis){
    if(type != 'platform') {
      for(let key in mallShippingAnalysis[type]){
        if(key != 'shipping_free_type'){
          if(!result[`mall_${key}`]){
            result[`mall_${key}`] = []
          }
          result[`mall_${key}`].push(`{mall:${type},${key}:${mallShippingAnalysis[type][key]}}`)
        }
      }
    }
  }
  Object.keys(result).forEach(key => {
    result[key] = `{${result[key].join('')}}`
  })
  return result
}

export function handleCalcuMallParam({ selectMallShip, mallInsuranceCheck, switchQsFlag, isClickedQsFreight, qsFreightSelected }) {
  let mall_params = []

  selectMallShip.forEach(mall => {
    let filterInsurance = mallInsuranceCheck.filter(insurance => insurance.mall_code == mall.mall_code) || []

    let switch_qs_flag = 0
    if (mall?.shipping_method?.qs_freight_status == 1) {
      switch_qs_flag = switchQsFlag || 0
      // 用户选择优先级高于abt
      if (isClickedQsFreight) switch_qs_flag = qsFreightSelected ? 1 : 2
    }

    mall_params.push({ mall_code: mall.mall_code, transport_type: mall?.shipping_method?.transport_type || '', use_insurance: filterInsurance[0]?.use_insurance || 0, switch_qs_flag })
  })
  return mall_params
}

export function handleMallInsuranceChange({ mallInsuranceCheck, insuranceMallData, mall_caculate_info }) {
  let isChange = false
  const { mall_list = [] } = insuranceMallData || {}
  const { mall_list: calcu_list = [] } = mall_caculate_info || {}

  mallInsuranceCheck = mallInsuranceCheck.map(item => {
    let filterInsurance = mall_list.find(mall => mall.mall_code == item.mall_code) || {}
    let filterCalcu = calcu_list.find(mall => mall.mall_code == item.mall_code) || {}
    if (filterInsurance?.insurance_type == 1 && filterCalcu?.isDisplayShippingInsurance == 0 && item.use_insurance != 0) {
      isChange = true
      return Object.assign({}, item, { use_insurance: 0 })
    } else {
      return item
    }
  })
  return { isChange, insuranceCheck: mallInsuranceCheck }
}

export function sortCartsByNum(list) {
  if(!list.length) return list

  let sortedList = list
  sortedList.sort((a, b) => b.quantity - a.quantity)

  const promotionItemIndex = sortedList.findIndex(item => item.promotion_type_id == '1000')
  if(promotionItemIndex > -1) { // 赠品置首位
    sortedList.unshift(sortedList.splice(promotionItemIndex, 1)[0])
  }

  return sortedList
}

export function sortTogetherBuyCart(list, addCartProducs) {
  // 根据addCartProducs 字段，排前面
  const arr = {
    front: [],
    pre: []
  }
  // 前，在addCartProducs记录里面。 后，没在addCartProducs记录里面
  addCartProducs.forEach(key => {
    const result = list.find(f => f.id === key)
    if(result){
      arr.front.push(result)
    }
  })
  arr.pre = list.filter(f => !addCartProducs.includes(f.id))

  return [...arr.front, ...arr.pre]
}

const getXtraCouponCode = ({ xtraProductBagInfo = [], xtraDiscountInfo = {} }) => {
  return xtraProductBagInfo.map(val => {
    let coupon_code = ''
    const discountInfo = xtraDiscountInfo?.allPaymentRightInfo?.[val.product_code] || {}
    const { total_discount } = discountInfo
    
    if(+total_discount?.amount > 0) {
      coupon_code = discountInfo.discount_values?.[0]?.coupon_code || ''
    } else {
      const rightConfig = val.right_config_list?.filter(item => item.privilege_cycle_num == 1) || []
      const privilege_info_list = rightConfig[0]?.privilege_info_list || []

      coupon_code = privilege_info_list[0]?.coupon_code_list?.[0]?.coupon_code || ''
    }

    return ({ [val.product_code]: coupon_code.toUpperCase() })
  })
}

const getXtraCouponType = ({ productInfo, couponCode }) => {
  const privilegeInfoList = productInfo.right_config_list.map(item => item.privilege_info_list).flat(Infinity) || []
  const couponCodeList = privilegeInfoList.map(item => item.coupon_code_list).flat(Infinity) || []
  const couponTypeIndex = couponCodeList.findIndex(coupon => coupon.coupon_code?.toUpperCase() == couponCode.toUpperCase())

  return couponCodeList[couponTypeIndex]?.coupon_style || 0
}

export const getXtraDisplayData = async ({ xtraProductBagInfo = [], xtraDiscountInfo = {} }) => {
  const couponCodeData = getXtraCouponCode({ xtraProductBagInfo, xtraDiscountInfo })
  const coupon_code = couponCodeData.map(item => Object.values(item)?.[0] || '')

  const data = await getCouponListForXtra(coupon_code) || []

  let result = {}
  xtraProductBagInfo?.forEach(val => {
    const productCode = val?.product_code
    const couponCode = couponCodeData.find(i => !!i[productCode])?.[productCode] || ''

    const count = val.right_config_list?.map(item => +item.coupon_num).reduce((prev, curr) => {
      return prev + curr
    }, 0) || 0

    const couponType = getXtraCouponType({ productInfo: val, couponCode })
    result[productCode] = {
      type: couponType, count, couponCode,
      info: data?.find(_ => _.couponCode.toUpperCase() == couponCode) || {}
    }
  })
  return { data: result }
}

const getXtraMutipleBagCouponList = (xtraProductInfo) => {
  return new Promise(resolve => {
    schttp({
      method: 'POST',
      url: '/api/checkout/xtraCouponList/update',
      data: { xtraProductInfo }
    }).then(res => {
      if(res.code == 0 && res.info) {
        resolve(res.info || {})
      } else {
        resolve({})
      }
    }).catch(() => {
      resolve({})
    })
  })
}
export const updateXtraCouponList = async (xtraProductBagInfo) => {
  let xtraCouponListInfo = {}
  
  const result = await Promise.all(xtraProductBagInfo.map(async item => {
    return getXtraMutipleBagCouponList(item)
  }))

  xtraProductBagInfo.forEach((_, index) => { xtraCouponListInfo[_.product_code] = result[index] })

  return xtraCouponListInfo
}

export const getCouponListForXtra = (coupon_codes) => {
  return new Promise(resolve => {
    schttp({
      method: 'POST',
      url: '/api/coupon/getCouponListForXtra/query',
      data: {
        coupon_codes: coupon_codes,
        ignore_check: 1,
      }
    }).then(res => {
      if(res.code == 0 && res.info) {
        resolve(res.info.list || [])
      } else {
        resolve([])
      }
    }).catch(() => {
      resolve([])
    })
  })
}

export function isJSON(str) {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str)
      if(typeof obj == 'object' && obj ) {
        return true
      } else {
        return false
      }
    } catch(e) {
      return false
    }
  }
  return false
}

export function handleCustomerCartsData(carts = [], isOrder = false) {
  if(!carts.length) return carts

  try {
    let cartsList = carts.map(item => {
      // mallorder返回的json，需要自行解析
      let orderCustomData = { 
        customization_flag: item?.customization_flag || 0, 
        customization_info: item?.customization_info || {} 
      }
      try {
        const customization_info = orderCustomData.customization_info
        orderCustomData = Object.assign(orderCustomData, { customization_info: orderCustomData.customization_flag == 1 && isJSON(customization_info) ? JSON.parse(customization_info) : {} })
      } catch(e) {
        console.log(e)
      }

      const customData = isOrder ? orderCustomData : { 
        customization_flag: item?.product?.customization_flag || 0,
        customization_info: item?.product?.customization_info || {} 
      }
      const preview = customData.customization_info?.preview || {}
      const canCustoms = customData.customization_flag == 1 && !!preview.effects?.length

      if(canCustoms) {
        const goods_img = preview.effects?.[0] || item.product?.goods_img || ''
        let product = Object.assign(item.product, { goods_img: goods_img })
        return Object.assign(item, { product: product } )
      } else {
        return item
      }
    })
    return cartsList
  } catch(e) {
    return carts
  }
}

export async function handleTspLabels(carts, behaviorShow, isLowestPriceDays) {
  try {
    const machineLabel = BehaviorLabel.getLabelIdsByStr(behaviorShow)
    const goods = carts.map(item => item.product)
    await itemSer.getAtomicInfo({
      goods,
      fields: {
        realTimeTspLabels: {
          machine_label: machineLabel,
        }
      },
      pageKey: 'page_checkout'
    })
    goods.forEach((item) => {
      if (machineLabel.length) {
        item.showBehaviorLabel =  BehaviorLabel.getPriorityOneForNormal(behaviorShow, item?.realTimeTspLabels, item, {
          LowestPriceDays: isLowestPriceDays,
        })
      }
    })
    return carts.map((item, index) => ({ ...item, product: goods[index] }))
  } catch {
    return carts
  }
}

export function updateMallInsuranceCheck({ preMallInsuranceCheck = [], insuranceMallData = {}, forcedShippingByAbt = false }) {
  let mallInsuranceCheck = []
  const { mall_list = [] } = insuranceMallData
  if (!mall_list.length) return mallInsuranceCheck

  if(!preMallInsuranceCheck.length) {
    return mall_list.map(insurance => {
      const isForceCheck = forcedShippingByAbt && +insurance.is_force_tick === 1
      return { 
        mall_code: insurance.mall_code,
        use_insurance: isForceCheck ? 1 : insurance?.is_default || 0,
        insurance_type: insurance.insurance_type
      }
    })
  } else {
    mall_list.forEach(mall => {
      const filterInsurance = preMallInsuranceCheck.filter(insurance => insurance.mall_code == mall.mall_code) || []
      mallInsuranceCheck.push({ mall_code: mall.mall_code, use_insurance: filterInsurance[0]?.use_insurance || 0, insurance_type: mall.insurance_type })
    })
    return mallInsuranceCheck
  }
}

// 当前即将选中的运输方式的switch_qs_flag
export function handleCurrShipSwitchQsFlag({ switchQsFlagByAbt, shippingMethod, isClickedQsFreight, qsFreightSelected }) {
  let switch_qs_flag = 0
  // 当前abt配置的flag
  let switchQsFlag = switchQsFlagByAbt
  // 当前选中运输方式返回的是否展示入口
  if (shippingMethod?.qs_freight_status == 0) {
    switch_qs_flag = 0
  } else {
    switch_qs_flag = switchQsFlag
    // 用户选择优先级高于abt
    if (isClickedQsFreight) switch_qs_flag = qsFreightSelected ? 1 : 2
  }

  return switch_qs_flag
}

const isShipAvailable = ({ method, orderInterceptByAbt }) => {
  const interceptList = [1, 2, 3, 7]
  const isAvailable = !orderInterceptByAbt || (orderInterceptByAbt && interceptList.includes(+method.rule_info?.hint_type))

  return method.is_available == 1 || (method.is_available == 0 && method.is_available_msg == 9 && isAvailable)
}

// 还原用户上次选择的运输方式 或者 修复完受限切换该方式；没有走默认选中逻辑
const handlePrevTransportType = ({ prevMallParams = [], mallCode = '' }) => {
  let transportType = ''
  if(!!prevMallParams.length && !!mallCode) {
    const currPrevMallParams = prevMallParams.find(mall => mall.mall_code == mallCode) || {}
    transportType = currPrevMallParams.transport_type || ''
  }

  return transportType
}

const handleAvailableShipInx = ({ 
  shipping_methods = [], 
  transport_type = '', 
  checkType = false, 
  isShop = false, 
  orderInterceptByAbt = false,
}) => {
  let inx = 0
  if(!shipping_methods.length) return inx

  if(!!transport_type) { // 即将要选中的运输方式 is_available_msg=9类型前置，不会在此处触发（这里一定是可用的）
    inx = shipping_methods.findIndex(method => method.transport_type == transport_type)
  } else {
    /**
     * 1.如果abt开启：
     * 1.1----如果是部分可用，部分不可用：
     * 这里只需要走is_available=1的即可
     * 1.2----如果全部不可用：
     * 2.如果abt关闭：
     * 2.1----如果是部分可用，部分不可用：
     * 这里走is_available=1或者is_available=0&msg=9的即可
     * 2.2----如果全部不可用：
     * 这种才需要走所谓的优先级，找=9的，如果没有9，再走线上任意一个
     */
    // 不区分是否宅店配
    if(!checkType) { // 以第一个mall决定多mall宅配店配类型
      if(orderInterceptByAbt) {
        inx = shipping_methods.findIndex(method => {
          return method.is_available == 1
        })
        if(inx == -1) {
          inx = shipping_methods.findIndex(method => {
            return method.is_available_msg == 9
          })
        }
      } else {
        inx = shipping_methods.findIndex(method => {
          const isAvaliable = method.is_available == 1 || (method.is_available == 0 && method.is_available_msg == 9)
          return isAvaliable
        })
      }
    } else {
      if(isShop) { // 店配
        if(orderInterceptByAbt) {
          inx = shipping_methods.findIndex(method => {
            return TRANSPORT_TYPE_SHOP.includes(method.transport_type) && method.is_available == 1
          })
          if(inx == -1) {
            inx = shipping_methods.findIndex(method => {
              return TRANSPORT_TYPE_SHOP.includes(method.transport_type) && method.is_available_msg == 9
            })
          }
        } else {
          inx = shipping_methods.findIndex(method => {
            return TRANSPORT_TYPE_SHOP.includes(method.transport_type) && (method.is_available == 1 || (method.is_available == 0 && method.is_available_msg == 9))
          })
        }
      } else { // 宅配
        if(orderInterceptByAbt) {
          inx = shipping_methods.findIndex(method => !TRANSPORT_TYPE_SHOP.includes(method.transport_type) && method.is_available == 1)
          if(inx == -1) inx = shipping_methods.findIndex(method => !TRANSPORT_TYPE_SHOP.includes(method.transport_type) && method.is_available_msg == 9)
        } else {
          inx = shipping_methods.findIndex(method => !TRANSPORT_TYPE_SHOP.includes(method.transport_type) && (method.is_available == 1 || (method.is_available == 0 && method.is_available_msg == 9)))
        }
      }
    }
  }
  inx = inx == -1 ? 0 : inx

  return inx
}

/**
 * 即将切换的运输方式
 *
 * @export
 * @param {*} { mallShippingMethods, mallCode = '', transportType = '', prevMallParams = [], orderInterceptByAbt }
 * @return {object} prevShippingMethod - 上次选中的运输方式，如果新的数据中没有该运输方式则返回第一个可用的同类型运输方式
 */
export function handleNextShippingMethod ({ 
  mallShippingMethods, 
  mallCode = '', 
  transportType = '', 
  prevMallParams = [], 
  orderInterceptByAbt,
}) {
  let prevShippingMethod = {}
  let shippingMethod = {}
  let notIncludeMall = true
  const { mall_list = [] } = mallShippingMethods
  if (!mall_list.length || !mallCode || !transportType) return { prevShippingMethod, shippingMethod, notIncludeMall }


  // 重新请求接口后当前运输方式存在且可用直接返回，否则走默认初始化，找第一个可用的返回
  const findShippingMethod = ({ mall_list, mallCode, transportType }) => {
    let mallInx = -1
    let index = -1
    let shippingMethod = {}

    mallInx = mall_list.findIndex(mall => mall.mall_code == mallCode)
    if(mallInx != -1) {
      // 此处需要返回可用的
      index = mall_list[mallInx].shipping_methods?.findIndex(method => {
        const isAvaliable = method.transport_type == transportType && isShipAvailable({ method, orderInterceptByAbt })
        return isAvaliable
      })
      if(index != -1) shippingMethod = mall_list[mallInx].shipping_methods[index]
    }

    return { mallInx, index, shippingMethod }
  }
  const { mallInx, index, shippingMethod: currMethod } = findShippingMethod({ mall_list, mallCode, transportType })
  let prevTransportType = handlePrevTransportType({ prevMallParams, mallCode })
  const { shippingMethod: prevMethod } = findShippingMethod({ mall_list, mallCode, transportType: prevTransportType })
  prevShippingMethod = prevMethod


  if(mallInx == -1) return { prevShippingMethod, shippingMethod, notIncludeMall }

  if(index != -1) {
    shippingMethod = currMethod
  } else {
    let shipInx = 0

    if(mall_list.length > 1) { // 多mall场景需要寻找当前宅店配同一类型的第一个可用
      let isShopTransit = TRANSPORT_TYPE_SHOP.includes(transportType)
      shipInx = handleAvailableShipInx({ 
        shipping_methods: mall_list[mallInx].shipping_methods || [], 
        orderInterceptByAbt, 
        checkType: true, 
        isShop: isShopTransit,
      })
    } else { // 单mall场景直接寻找第一个可用
      shipInx = handleAvailableShipInx({ 
        shipping_methods: mall_list[mallInx].shipping_methods || [], 
        orderInterceptByAbt,
      })
    }
    shippingMethod = mall_list[mallInx].shipping_methods[shipInx]
  }

  return { prevShippingMethod, shippingMethod, notIncludeMall: false }
}

// 判断此处即将选中的运输方式是否还会触发限制
export function isShipOrderIntercept({ 
  mallShippingMethods, 
  shippingMethod, 
  mallCode, 
  orderInterceptByAbt,
}) {
  let isOrderIntercept = false
  let orderInterceptInfo = {
    isIntercept: false,
    hintType: null
  }

  const { mall_list = [] } = mallShippingMethods
  if(!!mall_list.length) {
    const filterMall = mall_list.find(mall => mall.mall_code == mallCode) || {}
    const filterShippingMethod = filterMall.shipping_methods?.find(method => method.transport_type == shippingMethod?.transport_type) || {}

    const interceptList = [1, 2, 3, 7]
    isOrderIntercept = orderInterceptByAbt && filterShippingMethod.is_available == 0 && filterShippingMethod.is_available_msg == 9 && interceptList.includes(+filterShippingMethod.rule_info?.hint_type)

    // 请求后不存在该mall该运输方式，直接按不触发限制，走默认选中逻辑
    if(isOrderIntercept) orderInterceptInfo = Object.assign(orderInterceptInfo, { isIntercept: true, hintType: +filterShippingMethod.rule_info?.hint_type })
  }

  return orderInterceptInfo
}

// 是否所有mall店配运输方式可用
const isAllShopAvailable = ({ 
  mallShippingMethods, 
  orderInterceptByAbt,
}) => {
  let isAvailable = true
  const { mall_list = [] } = mallShippingMethods
  if (!mall_list.length) return false

  mall_list.forEach(mall => {
    const index = mall.shipping_methods?.findIndex(method => {
      return TRANSPORT_TYPE_SHOP.includes(method.transport_type) && isShipAvailable({ method, orderInterceptByAbt })
    })

    if(index == -1) isAvailable = false
  })

  return isAvailable
}

export function initMallShipIndex ({ 
  mallShippingMethods, 
  has_shop_transit_address, 
  orderInterceptByAbt, 
  prevMallParams = [],
}) {
  let mallShipIndex = []
  const { mall_list = [] } = mallShippingMethods
  if (!mall_list.length) return mallShipIndex

  let isShopTransit = false
  let currTransportType = ''

  for (let i = 0; i < mall_list.length; i++) {
    const mall = mall_list[i] || {}
    let index = 0

    // 宅店配切换场景前置，走到该处时所有mall的宅店配类型应该是一致的
    if(i == 0) {
      if(!prevMallParams.length) {
        const isAllAvailable = isAllShopAvailable({ 
          mallShippingMethods, 
          orderInterceptByAbt, 
        })
        // 初始化时无法同时选中店配或者无店配地址，则全部选中宅配
        if(isAllAvailable && has_shop_transit_address) {
          index = handleAvailableShipInx({ 
            shipping_methods: mall.shipping_methods || [], 
            orderInterceptByAbt, 
          })
        } else { // 非所有mall有可用店配，默认走宅配逻辑
          index = handleAvailableShipInx({ 
            shipping_methods: mall.shipping_methods || [], 
            orderInterceptByAbt, 
            checkType: true, 
            isShop: false, 
          })
        }
      } else { // 此处不会触发多mall宅配店配逻辑
        currTransportType = handlePrevTransportType({ prevMallParams, mallCode: mall.mall_code })
        index = handleAvailableShipInx({ 
          shipping_methods: mall.shipping_methods, 
          transport_type: currTransportType, 
          orderInterceptByAbt, 
        })
      }

      // 多mall下需要同时选择店配或者宅配
      isShopTransit = TRANSPORT_TYPE_SHOP.includes(mall.shipping_methods?.[index]?.transport_type)
    } else {
      currTransportType = handlePrevTransportType({ prevMallParams, mallCode: mall.mall_code })
      index = handleAvailableShipInx({ 
        shipping_methods: mall.shipping_methods, 
        transport_type: currTransportType, 
        orderInterceptByAbt, 
        checkType: true, 
        isShop: isShopTransit, 
      })
    }
    mallShipIndex.push({ mall_code: mall.mall_code, ship_index: index })
  }

  return mallShipIndex
}

// 多mall触发宅店配切换时运输方式数据封装
export function handleMallShipIndex ({ 
  mallShippingMethods, 
  orderInterceptByAbt, 
  transportType = '', 
  mallCode,
}) {
  let mallShipIndex = []
  const { mall_list = [] } = mallShippingMethods
  if (!mall_list.length) return mallShipIndex

  // 宅配切店配（其他mall选中第一个可用店配，无则切换失败）；店配切宅配（其他mall选中相同可用宅配运输方式，无则选中第一个可用宅配）；当前mall需选中当前运输方式
  // 当前触发切换的mall
  const selectedMallInx = mall_list.findIndex(mall => mall.mall_code == mallCode)
  const isShopTransit = TRANSPORT_TYPE_SHOP.includes(transportType)
  const isAllAvailable = isAllShopAvailable({ 
    mallShippingMethods, 
    orderInterceptByAbt, 
  })

  if(!isShopTransit) { // 宅配
    mall_list.forEach(mall => {
      let finalIndex = 0
      let index = 0

      const selectIndex = mall?.shipping_methods?.findIndex(method => method.transport_type == transportType && isShipAvailable({ method, orderInterceptByAbt }))
      if(selectIndex == -1) {
        if(orderInterceptByAbt) {
          index = mall?.shipping_methods?.findIndex(method => !TRANSPORT_TYPE_SHOP.includes(method.transport_type) && method.is_available == 1)
          if(index == -1) index = mall?.shipping_methods?.findIndex(method => !TRANSPORT_TYPE_SHOP.includes(method.transport_type) && method.is_available_msg == 9)
        } else {
          index = mall?.shipping_methods.findIndex(method => !TRANSPORT_TYPE_SHOP.includes(method.transport_type) && (method.is_available == 1 || (method.is_available == 0 && method.is_available_msg == 9)))
        }
      }
      finalIndex = selectIndex == -1 ? index : selectIndex

      mallShipIndex.push({ mall_code: mall.mall_code, ship_index: finalIndex == -1 ? 0 : finalIndex })
    })
  } else { // 店配
    if(isAllAvailable) {
      mall_list.forEach((mall, inx) => {
        let index = 0

        if(inx == selectedMallInx) { // 当前mall选中的一定可用（不可用会先弹限制弹窗）
          index = handleAvailableShipInx({ 
            shipping_methods: mall.shipping_methods, 
            transport_type: transportType, 
            orderInterceptByAbt, 
            checkType: true, 
            isShop: true,
          })
        } else {
          if(orderInterceptByAbt) {
            index = mall?.shipping_methods?.findIndex(method => {
              return TRANSPORT_TYPE_SHOP.includes(method.transport_type) && method.is_available == 1
            })
            if(index == -1) {
              index = mall?.shipping_methods?.findIndex(method => {
                return TRANSPORT_TYPE_SHOP.includes(method.transport_type) && method.is_available_msg == 9
              })
            }
          } else {
            index = mall?.shipping_methods.findIndex(method => {
              return TRANSPORT_TYPE_SHOP.includes(method.transport_type) && (method.is_available == 1 || (method.is_available == 0 && method.is_available_msg == 9))
            })
          }
        }
  
        mallShipIndex.push({ mall_code: mall.mall_code, ship_index: index == -1 ? 0 : index })
      })
    } else { // 若不存在多mall均可用店配，店配切换失败，走默认选中逻辑
      mallShipIndex = initMallShipIndex({ mallShippingMethods, orderInterceptByAbt })
    }
  }

  return mallShipIndex
}

// 多mall场景下 判断是否需要出现宅配店配互斥弹窗提示
export function isShowMultiMallAddressMutex ({ mallShippingMethods, shippingMethod, isShopTransit } = {}) {
  if ((mallShippingMethods?.mall_list?.length || 0) <= 1) return false
  const isSameShippingType = function (obj) {
    if (TRANSPORT_TYPE_SHOP.includes(obj.transport_type) && isShopTransit) return true
    if (!TRANSPORT_TYPE_SHOP.includes(obj.transport_type) && !isShopTransit) return true
    return false
  }
  const sameType = isSameShippingType(shippingMethod)
  return !sameType
}

export async function handleShipInterceptGoods(match_carts) {
  const mergeCartField = (cartItem, targetData) => {
    cartItem.attr_value_location = {
      ...(cartItem.attr_value_location || {}),
      size_rule_list: targetData.size_rule_list
    }
    cartItem.relatedProductIds = targetData.spu_products
    let supplementField = {
      relatedColor: targetData.spu_products,
      brand: targetData.brand,
      brand_logo: targetData.brand_logo,
      color_image: targetData.color_image,
      corner_brand_left: targetData.corner_brand_left,
      corner_brand_right: targetData.corner_brand_right,
      goods_img: targetData.goods_img,
      goods_url_name: targetData.goods_url_name,
      main_sale_attr_show_mode: targetData.mainSaleAttrShowMode,
      original_img: targetData.original_img,
      productRelationID: targetData.productRelationID,
      series_info_list: targetData.series_info_list,
      sku_sale_attr: targetData.sku_sale_attr,
    }
    cartItem.product = {
      ...cartItem.product,
      ...supplementField
    }
  }

  if(!match_carts.length) return []

  const goodsSpuInfo = await api.getSpuProductsCart(match_carts)
  if(goodsSpuInfo.length){
    goodsSpuInfo.forEach((spuProductsCartInfo) => {
      if(spuProductsCartInfo.code == 0 && spuProductsCartInfo.info?.data?.length){
        let spuProductsCartData = spuProductsCartInfo.info.data || []
        match_carts.forEach(item => {
          let targetData = spuProductsCartData.find(spuItem => spuItem.goods_id == item.product.goods_id && spuItem.sku_code == item.product.sku_code && spuItem.mall_code == item.product.mall_code)
          if(targetData){
            mergeCartField(item, targetData)
          }
        })
      }
    })
  }

  const limitData = match_carts.map(item => {
    const product = Object.assign({}, item.product, { salePrice: item.product.sale_price || {}, retailPrice: item.product.retail_price || {} })

    return Object.assign({}, item, { product })
  })

  return handleCustomerCartsData(limitData)
}

export async function handleShipFeeGoods(ruleList) {
  const mergeCartField = (cartItem, targetData) => {
    cartItem.attr_value_location = {
      ...(cartItem.attr_value_location || {}),
      size_rule_list: targetData.size_rule_list
    }
    cartItem.relatedProductIds = targetData.spu_products
    let supplementField = {
      relatedColor: targetData.spu_products,
      brand: targetData.brand,
      brand_logo: targetData.brand_logo,
      color_image: targetData.color_image,
      corner_brand_left: targetData.corner_brand_left,
      corner_brand_right: targetData.corner_brand_right,
      goods_img: targetData.goods_img,
      goods_url_name: targetData.goods_url_name,
      main_sale_attr_show_mode: targetData.mainSaleAttrShowMode,
      original_img: targetData.original_img,
      productRelationID: targetData.productRelationID,
      series_info_list: targetData.series_info_list,
      sku_sale_attr: targetData.sku_sale_attr,
    }
  
    cartItem = { 
      ...cartItem, 
      ...supplementField 
    }

    return cartItem
  }

  if(!ruleList.length) return []

  let requestArr = []
  ruleList.forEach(rule => {
    if(!!rule.product_shipping_price_list?.length) {
      requestArr.push(api.getSpuProductsCart(rule.product_shipping_price_list))
    } else {
      requestArr.push(Promise.resolve({ code: -1, info: {} }))
    }
  })

  await Promise.all(requestArr).then(data => {
    for(let i = 0; i < ruleList.length; i++) {
      const goodsSpuInfo = data?.[i] || []
      if(goodsSpuInfo.length){
        goodsSpuInfo.forEach((spuProductsCartInfo) => {
          if(spuProductsCartInfo.code == 0 && spuProductsCartInfo.info?.data?.length){
            let spuProductsCartData = spuProductsCartInfo.info.data || []

            ruleList[i].product_shipping_price_list = ruleList[i].product_shipping_price_list.map(item => {
              let targetData = spuProductsCartData.find(spuItem => spuItem.goods_id == item.goods_id && spuItem.sku_code == item.sku_code && spuItem.mall_code == item.mall_code)

              return targetData ? mergeCartField(item, targetData) : item
            })
          }
        })
      }
    }
  })

  return ruleList
}

export function isShippingMethodDisabled({ item, orderInterceptByAbt }) {
  return item.is_available == 0 && (item.is_available_msg != 9 || (orderInterceptByAbt && item.is_available_msg == 9 && +item.rule_info?.hint_type == 0))
}

/**
 * 根据不同的状态，输出场景
 * 是否有特价showDiscount、是否有分人群活动价isPreciseType、是否有可用的会费劵couponRightsCode、是否已勾选超省卡usedXtraCode、是否已经在下单页实际使用了会费券applyCouponsVir
 *  */
export const scenceBuildResult = ({ showDiscount, isPreciseType, couponRightsCode, usedXtraCode, applyCouponsVir }
= { showDiscount: false, isPreciseType: false, couponRightsCode: false, usedXtraCode: false, applyCouponsVir: false }) => {
  let result = 0
  if(!showDiscount && !isPreciseType && !couponRightsCode && !usedXtraCode)result = 1
  if(!showDiscount && !isPreciseType && !couponRightsCode && usedXtraCode)result = 2
  if(showDiscount && !isPreciseType && !couponRightsCode && !usedXtraCode)result = 3
  if(showDiscount && !isPreciseType && !couponRightsCode && usedXtraCode)result = 4
  if(isPreciseType && !couponRightsCode && usedXtraCode)result = 6
  if(!showDiscount && !isPreciseType && couponRightsCode && !usedXtraCode && !applyCouponsVir)result = 7
  if(!showDiscount && !isPreciseType && couponRightsCode && usedXtraCode && applyCouponsVir)result = 9
  if(!showDiscount && !isPreciseType && couponRightsCode && usedXtraCode && !applyCouponsVir)result = 8
  if(showDiscount && !isPreciseType && couponRightsCode && !usedXtraCode && !applyCouponsVir)result = 10
  if(showDiscount && !isPreciseType && couponRightsCode && usedXtraCode && !applyCouponsVir)result = 11
  if(showDiscount && !isPreciseType && couponRightsCode && usedXtraCode && applyCouponsVir)result = 12
  if(isPreciseType && couponRightsCode && !usedXtraCode && !applyCouponsVir)result = 13
  if(isPreciseType && couponRightsCode && usedXtraCode && !applyCouponsVir)result = 14
  if(isPreciseType && couponRightsCode && usedXtraCode && applyCouponsVir)result = 15
  if(isPreciseType && !couponRightsCode && !usedXtraCode) result = 5
      
  return result
}  
